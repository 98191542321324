export default {
	messages: {
		uk: {
			'AUTO.RIA рекомендує': 'AUTO.RIA рекомендує',
			'Можливості розміщення': 'Можливості розміщення',
			'Додати оголошення з продажу авто': 'Додати оголошення з продажу авто',
			'Просування авто в пошуку': 'Просування авто в пошуку',
			'Вигоди на ТОП до -45%': 'Вигоди на ТОП до -45%',
			'Вигоди на публікації до -45%': 'Вигоди на публікації до -45%',
			'Пошук': 'Пошук',
			'Пошук автомобілів': 'Пошук автомобілів',
			'автомобіля|автомобілів|автомобілів': 'автомобіля|автомобілів|автомобілів',
			'Пошук перевірених авто': 'Пошук перевірених авто',
			'Пошук нових авто': 'Пошук нових авто',
			'Пошук авто': 'Пошук авто',
			'Пошук з-за кордону': 'Пошук з-за кордону',
			'Автомобільні компанії': 'Автомобільні компанії',
			'Товари для авто': 'Товари для авто',
			'СТО': 'СТО',
			'Калькулятор середньої ціни': 'Калькулятор середньої ціни',
			'та': 'та',
			'авто': 'авто',
			'Авторозбірка': 'Авторозбірка',
			'Терміновий викуп': 'Терміновий викуп',
			'Поради для успішної покупки авто': 'Поради для успішної покупки авто',
			'Офіційні тести з ПДР України': 'Офіційні тести з ПДР України',
			'Перевірені авто на AUTO.RIA': 'Перевірені авто на AUTO.RIA',
			'Відгуки власників авто': 'Відгуки власників авто',
			'Перед купівлею авто': 'Перед купівлею авто',
			'Автомобільні послуги': 'Автомобільні послуги',
			'Нові': 'Нові',
			'Нові і б/у запчастини': 'Нові і б/у запчастини',
			'Шини та диски': 'Шини та диски',
			'Гаражі по Всій Україні': 'Гаражі по Всій Україні',
			'по Всій Україні': 'по Всій Україні',
			'Автосалони всієї України': 'Автосалони всієї України',
			'Автодилери вживаних авто': 'Автодилери вживаних авто',
			'Авто під замовлення з-за кордону': 'Авто під замовлення з-за кордону',
			'Оцінка вартості': 'Оцінка вартості',
			'Дізнайтесь ринкову вартість продажу авто': 'Дізнайтесь ринкову вартість продажу авто',
			'Оцінити вартість': 'Оцінити вартість',
			'Продаж авто': 'Продаж авто',
			'за 24 години': 'за 24 години',
			'Швидкий продаж авто за ринковою ціною': 'Швидкий продаж авто за ринковою ціною',
			'Продати за 24 години': 'Продати за 24 години',
			'Продаж': 'Продаж',
			'Додайте безкоштовне оголошення': 'Додайте безкоштовне оголошення',
			'про продаж авто': 'про продаж авто',
			'Продати авто': 'Продати авто',
			'Митний калькулятор': 'Митний калькулятор',
		},
		ru: {
			'AUTO.RIA рекомендує': 'AUTO.RIA рекомендует',
			'Можливості розміщення': 'Возможности размещения',
			'Додати оголошення з продажу авто': 'Добавить объявление о продаже авто',
			'Просування авто в пошуку': 'Продвижение авто в поиске',
			'Вигоди на ТОП до -45%': 'Выгоды на ТОП до -45%',
			'Вигоди на публікації до -45%': 'Выгоды на публикации до -45%',
			'Пошук': 'Поиск',
			'Пошук автомобілів': 'Поиск автомобилей',
			'автомобіля|автомобілів|автомобілів': 'автомобиля|автомобилей|автомобилей',
			'Пошук перевірених авто': 'Поиск проверенных авто',
			'Пошук нових авто': 'Поиск новых авто',
			'Пошук авто': 'Поиск авто',
			'Пошук з-за кордону': 'Поиск из-за рубежа',
			'Автомобільні компанії': 'Автомобильные компании',
			'Товари для авто': 'Товары для авто',
			'Офіційні тести з ПДР України': 'Официальные тесты по ПДД Украины',
			'СТО': 'СТО',
			'Калькулятор середньої ціни': 'Калькулятор средней цены',
			'та': 'и',
			'авто': 'авто',
			'Авторозбірка': 'Авторазборка',
			'Терміновий викуп': 'Срочный выкуп',
			'Поради для успішної покупки авто': 'Советы для успешной покупки авто',
			'Перевірені авто на AUTO.RIA': 'Проверенные авто на AUTO.RIA',
			'Відгуки власників авто': 'Отзывы владельцев авто',
			'Перед купівлею авто': 'Перед покупкой авто',
			'Автомобільні послуги': 'Автомобильные услуги',
			'Нові': 'Новые',
			'Нові і б/у запчастини': 'Новые и б/у запчасти',
			'Шини та диски': 'Шины и диски',
			'Гаражі по Всій Україні': 'Гаражи по Всей Украине',
			'по Всій Україні': 'по Всей Украине',
			'Автосалони всієї України': 'Автосалоны всей Украины',
			'Автодилери вживаних авто': 'Автодилеры б/у авто ',
			'Авто під замовлення з-за кордону': 'Авто под заказ из-за рубежа',
			'Оцінка вартості': 'Оценка стоимости',
			'Дізнайтесь ринкову вартість продажу авто': 'Узнайте рыночную стоимость продажи авто',
			'Оцінити вартість': 'Оценить стоимость',
			'Продаж авто': 'Продажа авто',
			'за 24 години': 'за 24 часа',
			'Быстрая продажа авто по рыночной цене': 'Быстрая продажа авто по рыночной цене',
			'Продать за 24 часа': 'Продать за 24 часа',
			'Продажа': 'Продажа',
			'Добавьте бесплатное объявление': 'Добавьте бесплатное объявление',
			'о продаже авто': 'о продаже авто',
			'Продать авто': 'Продать авто',
			'Митний калькулятор': 'Таможенный калькулятор',
		},
	}
};
