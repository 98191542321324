import axios from 'axios';
import {base} from './index';

export function suggestApi(target, params) {
	return axios.get(`${base}/api/suggest/${target}`, {params}).then(({data}) => data);
}

const mapBrandEntity = (search_type) => {
    switch (search_type) {
        case 'bu':
            return 'brands'
        case 'na':
            return 'brands_new';
        case 'all':
        default:
            return 'brands,brands_new';
    }
}

const mapModelEntity = (search_type) => {
    switch (search_type) {
        case 'bu':
            return 'models'
        case 'na':
            return 'models_new'
        case 'all':
        default:
            return 'models,models_new'
    }
}

const reduceResult = (result) => {
    return Array.isArray(result) ?
        Array.from(result.reduce((a, item) => {
            if (a.has(item?.value)) {
                const {count} = a.get(item?.value);
                a.set(item?.value, {...item, count: count + item?.count});
            } else {
                a.set(item?.value, item);
            }
            return a;
        }, new Map()).values()) :
        [];
}

export function suggestBrand({text = '', category}, search_type) {
    const params = {
        q: text,
        fields: 'name,value,count,category'
    };
    params['context[category]'] = category;

    const target = mapBrandEntity(search_type);

    return suggestApi(target, params)
        .then(reduceResult)
        .catch((error) => {
            console.warn('Ошибка при получении марок. suggest');
            throw error;
        });
}

export function suggestModel({text = '', category, brand}, search_type) {
    const params = {
        q: text,
        fields: 'name,value,count,category,brand'
    };
    if (category && brand) {
        params['context[category_brand]'] = `${category}_${brand}`;
    } else {
        params['context[brand]'] = brand;
    }

    const target = mapModelEntity(search_type);

    return suggestApi(target, params)
        .then(reduceResult)
        .catch((error) => {
            console.warn('Ошибка при получении моделей. suggest');
            throw error;
        });
}
