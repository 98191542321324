
const links = [
    {
        linkProps: {
            href: `https://auto.ria.com/notice/b9bd08b86b523d36071aa23edde0e529/120850054?utm_source=baner_holovna24&utm_medium=baner_holovna24&utm_campaign=baner_holovna24&utm_content=nc_buyers&r_audience=nc_buyers&r_source=baner_holovna24&r_medium=baner_holovna24&r_campaign=baner_holovna24`,
            target: '_blank',
            class : 'unlink'
        },
        mobileSrc:'https://css.riastatic.com/images/brk/patriotic/ayoi-m',
        desktopSrc:'https://css.riastatic.com/images/brk/patriotic/ayoi',
    },
];
export default links;
