export default {
  blocks: [
    {
      id: '1',
      title: 'Можливості розміщення',
      list: [
        {
          id: '11',
          title: 'Просування авто в пошуку',
          href: (langPrefix) => `${langPrefix}/help/promo-view/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_promoview', 'Business'],
        },
        {
          id: '12',
          title: 'Вигоди на ТОП до -45%',
          href: (langPrefix) => `${langPrefix}/help/top-optom/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_top_optom', 'Business'],
        },
        {
          id: '13',
          title: 'Вигоди на публікації до -45%',
          href: (langPrefix) => `${langPrefix}/autoseller/addpackage/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_autoseller', 'Business'],
        },
      ],
    },
    {
      id: '2',
      title: 'Пошук автомобілів',
      list: [
        {
          id: '21',
          title: 'Пошук перевірених авто',
          href: (langPrefix) => `${langPrefix}/search/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_search_bu', 'Search_auto'],
        },
        {
          id: '22',
          title: 'Пошук нових авто',
          href: (langPrefix) => `${langPrefix}/newauto/search/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_search_newauto', 'Search_auto'],
        },
        {
          id: '23',
          title: 'Пошук з-за кордону',
          href: (langPrefix) => `${langPrefix}/autointer/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_autointer', 'Search_auto'],
        },
      ],
    },
    {
      id: '3',
      title: 'Автомобільні компанії',
      list: [
        {
          id: '31',
          title: 'Автосалони всієї України',
          href: (langPrefix) => `${langPrefix}/newauto/autosalons/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_autosalons', 'Companies'],
        },
        {
          id: '32',
          title: 'Автодилери вживаних авто',
          href: (langPrefix) => `${langPrefix}/dealers/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_dealers', 'Companies'],
        },
        {
          id: '33',
          title: 'Авто під замовлення з-за кордону',
          href: (langPrefix) => `${langPrefix}/avto-pod-zakaz/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_pod_zakaz', 'Companies'],
        },
      ],
    },
    {
      id: '4',
      title: 'Товари для авто',
      list: [
        {
          id: '41',
          title: 'Нові і б/у запчастини',
          href: (langPrefix) => `https://zapchasti.ria.com${langPrefix}`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_zapchasti', 'For_auto'],
        },
        {
          id: '42',
          title: 'Шини та диски',
          href: (langPrefix) => `https://zapchasti.ria.com${langPrefix}/c/kolesa-y-shyny-car/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_market', 'For_auto'],
        },
        {
          id: '43',
          title: 'Гаражі по Всій Україні',
          href: (langPrefix) => `https://dom.ria.com${langPrefix}/prodazha-garazhei/?utm_source=autoria&utm_medium=informer&utm_campaign=obyavagarag`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_garag', 'For_auto'],
        },
      ],
    },
    {
      id: '5',
      title: 'Перед купівлею авто',
      list: [
        {
          id: '51',
          title: 'Відгуки власників авто',
          href: (langPrefix) => `${langPrefix}/reviews/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_reviews', 'Services'],
        },
        {
          id: '52',
          title: 'Перевірені авто на AUTO.RIA',
          href: (langPrefix) => `${langPrefix}/autocheck/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_diagnostika', 'Services'],
        },
        {
          id: '53',
          title: 'Офіційні тести з ПДР України',
          href: () => 'https://pdr.infotech.gov.ua/?utm_source=auto&utm_medium=text&utm_campaign=partner&utm_term=main_blocks',
          gaEvents: ['MainPageNew', 'ClickOn_PDR_infotech', 'Services'],
        },
      ],
    },
    {
      id: '6',
      title: 'Автомобільні послуги',
      list: [
        {
          id: '61',
          title: 'Митний калькулятор',
          href: (langPrefix) => `${langPrefix}/rastamozhka-avto/calculator/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_Calculator', 'Services'],
        },
        {
          id: '62',
          title: 'Калькулятор середньої ціни',
          href: (langPrefix) => `${langPrefix}/price/average/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_autoservice_average', 'Services'],
        },
        {
          id: '63',
          title: 'СТО',
          href: (langPrefix) => `${langPrefix}/autoservice/sto/`,
          gaEvents: ['MainPageNew', 'ClickOn_Recommendations_autoservice_sto', 'Services'],
        },
      ],
    },
  ],
};
